<template>
  <div class="container-md py-3">
    <p>Effortlessly calculate the total amount of your cash in Indian Rupees.<br/>Just enter the number of notes you have for each denomination and the total amount will be calculated instantly.</p>
    <div class="row mt-4">
      <div class="align-self-center m-auto">
        <NoteRow v-for="(note, index) in notes" :key="note.value" :noteValue="note.value" :noteImage="note.image"
                 @updateOutput="updateTotal" :ref="'note' + index" @keydown.enter="focusNextField(index)"/>
      </div>
    </div>
    <div class="row mt-4 d-flex justify-content-center">
      <div :class="['col-lg-6 col-xl-6 col-12 card shadow-md mb-2', theme === 'light' ? 'bg-light text-dark' : 'bg-dark text-white']">
        <div class="card-body justify-content-center">
          <b><p class="card-text">Total Amount: {{ formatPrice(total) }}</p></b>
          <b v-if="total > 0">[{{ toWords }} Rupees]</b>
          <p class="card-text">Total Notes: {{ totalNotes }}</p>
          <button @click="resetFields" class="btn btn-danger">Reset</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NoteRow from './NoteRow.vue';

export default {
  name: 'calculatorApp',
  components: {
    NoteRow
  },
  data() {
    return {
      notes: [
        {value: 500, image: require(`../assets/ic_note_fivehundred.webp`), count: 0},
        {value: 200, image: require(`../assets/ic_note_twohundred.webp`), count: 0},
        {value: 100, image: require(`../assets/ic_note_hundred.webp`), count: 0},
        {value: 50, image: require(`../assets/ic_note_fifty.webp`), count: 0},
        {value: 20, image: require(`../assets/ic_note_twenty.webp`), count: 0},
        {value: 10, image: require(`../assets/ic_note_ten.webp`), count: 0},
        {value: 5, image: require(`../assets/ic_note_five.webp`), count: 0},
        {value: 2, image: require(`../assets/ic_note_two.webp`), count: 0},
        {value: 1, image: require(`../assets/ic_note_one.webp`), count: 0}
      ],
      total: 0,
      totalNotes: 0,
      toWords: ''
    };
  },
  props: {
    theme: String
  },
  methods: {
    calculate() {
      this.total = this.notes.reduce((sum, note) => sum + (note.value * note.count), 0);
      this.totalNotes = this.notes.reduce((sum, note) => sum + note.count, 0);
      this.toWords = this.convertToWords(this.total);
    },
    updateTotal(noteValue, noteCount) {
      const note = this.notes.find(n => n.value === noteValue);
      if (note) {
        note.count = noteCount;
      }
      this.calculate();
    },
    formatPrice(value) {
      return new Intl.NumberFormat('en-IN', {style: 'currency', currency: 'INR'}).format(value);
    },
    convertToWords(total) {
      total = parseInt(total);
      const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
      const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
      const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
      const groups = ['', 'Thousand', 'Lakh', 'Crore'];

      if (total >= 1e9) return 'Amount too large';
      if (total === 0) return 'Zero';

      let words = '';
      let group = 0;

      while (total > 0) {
        let chunk;

        if (group === 0) {
          chunk = total % 1000;
          total = Math.floor(total / 1000);
        } else {
          chunk = total % 100;
          total = Math.floor(total / 100);
        }

        if (chunk) {
          let chunkWords = '';
          if (chunk >= 100) {
            chunkWords += ones[Math.floor(chunk / 100)] + ' Hundred ';
            chunk %= 100;
          }
          if (chunk >= 11 && chunk <= 19) {
            chunkWords += teens[chunk - 11] + ' ';
          } else {
            if (chunk >= 10) {
              chunkWords += tens[Math.floor(chunk / 10)] + ' ';
              chunk %= 10;
            }
            if (chunk > 0) {
              chunkWords += ones[chunk] + ' ';
            }
          }
          if (group < groups.length) {
            words = chunkWords + groups[group] + ' ' + words;
          } else {
            words = chunkWords + ' ' + words;
          }
        }
        group++;
      }
      return words.trim();
    },
    resetFields() {
      //reload the page
      location.reload();
    },
    focusNextField(index) {
      this.$nextTick(() => {
        const nextField = this.$refs['note' + (index + 1)];
        if (Array.isArray(nextField) && nextField.length > 0) {
          nextField[0].$el.querySelector('input').focus();
        } else if (nextField && nextField.$el) {
          nextField.$el.querySelector('input').focus();
        } else if (nextField) {
          nextField.focus();
        }
      });
    }
  }
};
</script>

<style scoped>
</style>