<template>
  <div class="note-row d-flex align-items-center justify-content-center">
    <img :src="noteImage" alt="Note Image" class="note-image mr-3">
    <span>X</span>
    <input type="number" v-model.number="noteCount" @input="calculateOutput" class="form-control mx-3" style="width: 100px;" min="0">
    <span>=</span>
    <span class="note-ans">{{ output }}</span>
  </div>
</template>

<script>
export default {
  props: {
    noteValue: Number,
    noteImage: String
  },
  data() {
    return {
      noteCount: 0,
      output: 0
    };
  },
  methods: {
    calculateOutput() {
      this.output = this.noteCount * this.noteValue;
      this.$emit('updateOutput', this.noteValue, this.noteCount);
    },
    resetNote() {
      this.noteCount = 0;
      this.output = 0;
      this.calculateOutput();
    }
  }
};
</script>

<style scoped>
.note-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.note-image {
  width: 80px;
  height: auto;
  margin-right: 10px;
}
.note-ans {
  font-size: 1.1em;
  min-width: 150px;
  text-align: center;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 3px;
  margin-left: 10px;
}
input {
  width: 160px;
  margin: 0 10px;
}
</style>