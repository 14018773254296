<template>
  <nav :class="['navbar', theme === 'light' ? 'navbar-light bg-light text-dark' : 'navbar-dark bg-dark text-light']">
    <div class="container">
      <a class="navbar-brand d-flex align-items-center" href="#">
        <img src="./assets/logo.webp" width="40" height="40" class="d-inline-block align-top" alt="">
        &nbsp;<b>Cash Calculator India - Money Counter</b>
      </a>
      <div class="d-flex align-items-center justify-content-end">
        <p class="fs-6 m-0"><b>Download App</b></p>&nbsp;&nbsp;
        <a href="https://play.google.com/store/apps/details?id=com.cashcalculator.india" target="_blank">
          <i class="fab fa-google-play fa-2x"></i>
        </a>&nbsp;&nbsp;
        <a href="#">
          <i class="fab fa-app-store-ios fa-2x"></i>
        </a>
        <button @click="toggleTheme" class="btn btn-sm btn-outline-secondary ms-3">
          {{ theme === 'light' ? 'Dark Mode' : 'Light Mode' }}
          <i :class="theme === 'light' ? 'fas fa-moon' : 'fas fa-sun'"></i>
        </button>
      </div>
    </div>
  </nav>
  <div :class="theme">
    <!--  <img alt="Vue logo" src="./assets/logo.webp">-->
    <calculator :theme="theme"/>
    <footer class="footer mt-auto py-4 bg-dark">
      <div class="container text-center">
        <div class="row">
          <div class="col-12">
            <h5 class="text-white mb-2">Download Our App</h5>
            <a href="https://play.google.com/store/apps/details?id=com.cashcalculator.india" target="_blank">
              <img src="./assets/playstore.webp" alt="Download on Play Store" class="img-fluid"
                   style="max-width: 150px;">
            </a>&nbsp;
            <a href="#">
              <img src="./assets/appstore.webp" alt="Download on App Store" class="img-fluid" style="max-width: 150px;">
            </a>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <span class="text-muted">CashCalculatorIndia.com © {{ currentYear }} <a href="https://www.strivewin.com"
                                                            target="_blank">StriveWin</a> | All Right Reserved. </span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import calculator from './components/calculator.vue'

export default {
  name: 'App',
  components: {
    calculator
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      theme: localStorage.getItem('theme') || 'light'
    }
  },
  methods: {
    toggleTheme() {
      this.theme = this.theme === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', this.theme);
    }
  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.light {
  background-color: #ffffff;
  color: #2c3e50;
}

.dark {
  background-color: #000000;
  color: #e7e7e7;
}
</style>
